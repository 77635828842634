<script setup lang="ts">
  const currentStatus = defineModel<'doctors'|'recruiter'>({ required: true });
</script>

<template>
  <div class="main-container toggle-container">
    <div class="toggle">
      <button
        type="button"
        class="toggle-button"
        :class="{'active': currentStatus === 'doctors'}"
        @click="currentStatus = 'doctors'"
      >
        Все врачи
      </button>
      <button
        type="button"
        class="toggle-button"
        :class="{'active': currentStatus === 'recruiter'}"
        @click="currentStatus = 'recruiter'"
      >
        <IconStars filled class="icon" />
        <span class="recruiter-text">Подбор врача</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
}
.toggle {
  width: 571px;
  display: flex;
  padding: 2px;
  border-radius: 40px;
  background-color: #EAE6E6;
}
.toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 4px;
  padding: 14px 16px;
  border-radius: 34px;
  background-color: transparent;
  text-wrap: nowrap;

  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.active {
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
}
.icon {
  font-size: 24px;
  margin: 0px;
}
.recruiter-text {
  background: linear-gradient(to right, #E417FF, #1B89FF);
  background-clip: text;
  color: transparent;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
</style>
